<template>
  <v-card shaped outlined class="pa-4">
    <v-overlay absolute opacity="0.9" :value="qrOverlay">
      <v-progress-circular
        color="primary"
        v-if="qrProgress"
        size="100"
        indeterminate
      />
      <v-img
        max-width="40vh"
        src="qr-contact.svg"
        contain
        eager
        @load="qrProgress = false"
      />
    </v-overlay>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-click-outside="hideQr"
          @click="qrOverlay = true"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon large>mdi-qrcode</v-icon>
        </v-btn>
      </template>
      <span>vCard QR code</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          href="AndersonSoftwareLLC-vcard.vcf"
          icon
        >
          <v-icon large>mdi-card-account-details-outline</v-icon>
        </v-btn>
      </template>
      <span>Download vCard</span>
    </v-tooltip>
    <v-img
      src="AndersonSoftwareLLC.svg"
      height="150"
      alt="Anderson Software LLC"
      contain
      d-flex
      flex-row
    />
    <v-card-title class="justify-center">
      Contact Information
    </v-card-title>
    <v-container>
      <v-row justify="space-around">
        <v-col cols="auto">
          <v-container>
            <span>
              Brandon Anderson:
            </span>
            <div>
              <v-icon large>mdi-phone</v-icon>
              <span>1-(602)-892-9794</span>
            </div>
            <div>
              <v-icon large>mdi-fax</v-icon>
              <span>1-(602)-427-5630</span>
            </div>
            <div>
              <v-icon large>mdi-email</v-icon>
              <span>
                <a href="mailto:Brandon@AndersonSoftware.io">
                  Brandon@AndersonSoftware.io
                </a>
              </span>
            </div>
          </v-container>
        </v-col>
        <v-col cols="auto">
          <div>
            <v-container>
              <v-row dense>
                <v-col align-content="start" cols="auto" offset="2">
                  <span class="pl-1">Business Mailing Address:</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="auto" align-self="center">
                  <v-icon class="pr-1" large>mdi-mail</v-icon>
                </v-col>
                <v-col cols="auto">
                  <address>
                    <span class="font-weight-bold">
                      Anderson Software LLC
                    </span>
                    <br />
                    <span>1753 E. Broadway Road</span>
                    <br />
                    <span>STE 101-507</span>
                    <br />
                    <span>Tempe, AZ 85282</span>
                  </address>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <span class="text-caption">
      Anderson Software LLC operates in Mountain Standard Time (Arizona Time
      without Daylight Savings). However, to meet our clients' needs, we tend to
      be available most hours, night and day. Feel free to call, even if we miss
      your call, we will return it as soon as possible.
    </span>
  </v-card>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      qrOverlay: false,
      qrProgress: true
    }
  },
  computed: {},
  methods: {
    hideQr() {
      if (this.qrOverlay === true) {
        this.qrProgress = true
        this.qrOverlay = false
      }
    }
  }
}
</script>
