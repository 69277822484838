<template>
  <v-col>
    <v-card
      :height="height"
      width="370"
      :elevation="elevation"
      flat
      :light="light"
      class="ma-auto"
    >
      <v-card-title :class="classN" style="word-break: normal;">
        <v-icon
          v-if="showIcon"
          x-large
          class="pr-4"
          aria-hidden
          :color="iconColor"
          >{{ icon }}</v-icon
        >
        {{ title }}
      </v-card-title>
      <div class="pa-4 text-center">
        <slot></slot>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Sample Title'
    },
    elevation: {
      type: Number,
      default: 1
    },
    classN: {
      type: String,
      default: 'justify-center text-center'
    },
    height: {
      type: String,
      default: '100%'
    },
    icon: {
      type: String,
      default: ''
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showIcon() {
      return this.icon !== ''
    },
    iconColor() {
      if (this.light) {
        return this.$vuetify.theme.themes.light.primary
      } else {
        return this.$vuetify.theme.themes.dark.primary
      }
    }
  }
}
</script>
