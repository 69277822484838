<template>
  <v-sheet tile :color="vueColor" :elevation="elevation" :class="vueClass">
    <v-parallax v-if="isParallax" :height="slotHeight" :src="img">
      <v-container :fluid="fluid">
        <slot></slot>
      </v-container>
    </v-parallax>
    <v-img
      v-if="isImage"
      v-resize="updateHeight"
      :src="img"
      :max-height="imgHeight"
      eager
    >
      <div
        class="fill-height"
        style="background: radial-gradient(rgba(19, 30, 42, 0.7), rgba(19, 30, 42, 0.55));"
      >
        <v-container ref="slotRef" v-mutate="updateHeight" :fluid="fluid">
          <slot></slot>
        </v-container>
      </div>
    </v-img>
    <v-container v-if="isPlain" :fluid="fluid">
      <slot></slot>
    </v-container>
  </v-sheet>
</template>

<script>
import colors from 'vuetify/es5/util/colors'
import { background } from '~/middleware/theme'

export default {
  props: {
    color: {
      type: String,
      default: background
    },
    vueClass: {
      type: String,
      default: ''
    },
    elevation: {
      type: Number,
      default: 0
    },
    parallax: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 0
    },
    fluid: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgHeight: 500
    }
  },
  computed: {
    vueColor() {
      if (/colors.*\.*/.test(this.color)) {
        const parts = this.color.split('.')
        return colors[parts[1]][parts[2]]
      } else {
        return this.color
      }
    },
    gradient() {
      const red = parseInt(background.substring(1, 3), 16)
      const green = parseInt(background.substring(3, 5), 16)
      const blue = parseInt(background.substring(5, 7), 16)
      const opacity = 0.5
      return `to top right, rgba(${red}, ${green}, ${blue}, ${opacity}), rgba(${red}, ${green}, ${blue}, ${opacity})`
    },
    isParallax() {
      if (this.parallax === true && this.img !== '') {
        return true
      }
      return false
    },
    isImage() {
      return this.img !== '' && this.isParallax === false
    },
    isPlain() {
      if (this.isParallax === false && this.isImage === false) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    if (this.isImage) {
      this.updateHeight()
    }
  },
  methods: {
    updateHeight() {
      this.imgHeight = this.$refs.slotRef.clientHeight
    }
  }
}
</script>
