<template>
  <div>
    <PageSection
      ref="banner"
      img="worktable-banner-full.jpg"
      :height="bannerHeight"
    >
      <v-row justify="center">
        <v-col>
          <v-img
            src="AndersonSoftwareLLC-dull.svg"
            height="150"
            alt="Anderson Software LLC"
            contain
            eager
          >
            <div
              style="background: radial-gradient(rgba(19, 30, 42, 0.9), rgba(19, 30, 42, 0.55));"
            ></div>
          </v-img>
        </v-col>
      </v-row>
      <h1 class="text-h2 text-sm-h1 text-center">
        Experts in Blockchain Consulting
      </h1>
      <h3 class="text-h3 text-center pt-8">
        Developing Innovative Solutions With Distributed Ledger Technology
      </h3>
      <p class="text-h6 text-center">
        At Anderson Software LLC, we help clients determine if blockchain is a
        good fit for their business and, if so, help them develop trustworthy
        technology.
      </p>
      <p class="text-h5 text-center">
        We offer free consultations; Find out if blockchain can improve your
        business!
      </p>
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn x-large outlined @click.stop="contactUs = true">
            Contact Us!
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <ContactUs :contact="contactUs" @input="contactUs = false">
          </ContactUs>
        </v-col>
      </v-row>
    </PageSection>
    <!-- <PageSection color="colors.blue.lighten1" elevation="2" fluid> -->
    <PageSection fluid>
      <div
        class="py-md-15"
        style="overflow: hidden; position: relative; width: 100%;"
      >
        <particles
          id="particles-ts"
          class="align-self-stretch"
          style="position: absolute; width: 100%; z-index: auto;"
          :options="$options.particleJSON"
        ></particles>
        <v-row justify="center">
          <v-col cols="11" md="4">
            <BlockStage
              title="Feasibility Analysis"
              icon="mdi-beaker-check-outline"
              class="ml-md-auto ma-auto"
            >
              <p>
                We help clients determine their projects' feasibility, identify
                potential weaknesses in their plans, and determine if blockchain
                is a beneficial solution to their problem. Our experts can work
                with your team to avoid potential upfront issues and roadblocks
                you will likely encounter.
              </p>
              <p>
                Blockchain's added value comes with tradeoffs that should be
                seriously considered. Commissioning a feasibility consultation
                or proposal review can help companies determine early on if
                their projects are well suited for blockchain. Our experts in
                this field can help mitigate expensive oversights and determine
                the real risks of proposed plans.
              </p>
            </BlockStage>
          </v-col>
          <v-col cols="11" md="4">
            <BlockStage
              title="Project Planning/System Architecture"
              icon="mdi-notebook-check-outline"
              class="mx-md-auto ma-auto"
            >
              <p>
                Blockchain and Distributed Ledgers are often used when security
                and data integrity are paramount. Proper project architecture
                and keen attention to detail are requirements for building
                secure software. To guarantee the desired cryptographic
                properties, the project's use of blockchain is entirely
                dependent on which consensus algorithms are deployed and how the
                project architecture is designed. Often projects using
                blockchain are trying to develop software that no previous
                implementation exists for; as the adage goes 'never roll your
                own cryptography,' you should have an experienced consultant
                help in the project planning and design.
              </p>
              <p>
                Before digging into any project, its design and system
                architecture should be considered. There are many different
                blockchain frameworks and environments that your design can run
                on (e.g., Hyperledger, Ethereum, Quorum). Hiring experienced
                blockchain consultants can significantly ease the integration of
                blockchain into your projects.
              </p>
            </BlockStage>
          </v-col>
          <v-col cols="11" md="4">
            <BlockStage
              title="Project Development"
              icon="mdi-file-code-outline"
              class="mr-md-auto ma-auto"
            >
              <p>
                Often, clients want to use blockchain as another tool in their
                more extensive projects. Instead of training staff and dealing
                with the inevitable delays that slow down other priorities while
                learning new techniques, you can send your requirements to us.
                Anderson Software LLC has extensive experience with blockchain
                development and can assist in the development phase of any team.
                We will work with you in producing a project deliverable.
              </p>
              <p>
                If you are looking to have blockchain be a part of your core IP
                and feel uncomfortable relying on a third party developing your
                entire project, we can help develop segments of any size and
                perform code reviews to catch possible implementation mistakes
                and issues.
              </p>
            </BlockStage>
          </v-col>
        </v-row>
      </div>
    </PageSection>
    <PageSection color="#b8c4d4" class="grey--text text--darken-4 py-md-15">
      <h4 class="text-h4">Security as a forethought, not an afterthought</h4>
      <v-row class="py-5">
        <PointCards light icon="mdi-bug" title="Bugs are Costly">
          The costs of bugs from seemingly minor mistakes can be substantial. In
          2017 a single line of code in the Parity Multisig Wallet resulted in
          the loss of over 30 Million Dollars to affected users.
        </PointCards>
        <PointCards light icon="mdi-security" title="Secure Designs">
          We evaluate designs before anything else to identify risks and
          minimize potentially costly mistakes in plan development. With
          security as a primary focus, all designs are evaluated with stringent
          security concerns.
        </PointCards>
        <PointCards
          light
          icon="mdi-code-braces"
          title="Unit and Integration Tests"
        >
          Unit and Integration tests are always done standard with any project
          to ensure all development is performing as expected and to immediately
          identify potential regressions.
        </PointCards>
        <PointCards light icon="mdi-cog" title="Reliable Code">
          Our emphasis on secure designs allow for clients to trust their
          projects will do what they want. The immutable design of blockchain
          may make certain mistakes irreversible; having experienced consultants
          can help guide your project away from costly mistakes.
        </PointCards>
      </v-row>
      <p class="py-5 font-weight-normal">
        Blockchain and Distributed Ledger technology are often used because
        traditional trust models are insufficient. Blockchain offers data
        immutability; many project designs incorporate other properties such as
        privilege control, private data channels, and data authentication. These
        properties are guaranteed by cryptographic primitives working as a whole
        to build a secure solution. Many today working on blockchain technology
        do not have a solid foundation in the study of modern cryptography. This
        lack of understanding can lead to trivial and seemingly minuscule
        mistakes that can foil secure designs. Our specialists understand not
        only blockchain, distributed ledger, and popular frameworks but are also
        experts in modern cryptographic proofs, security principles, and
        cryptographic primitive design risks.
      </p>
    </PageSection>
    <PageSection class="py-md-15">
      <h4 class="text-h4 py-5">An Opportunity beyond Cryptocurrency</h4>
      <v-row class="py-5">
        <PointCards icon="fa-file-contract" title="Smart Contracts">
          Smart contracts are programs that can run on blockchain or blockchain
          frameworks such as Ethereum or Hyperledger, which can be interacted
          with and self execute on predetermined conditions.
        </PointCards>
        <PointCards icon="fa-project-diagram" title="Distributed Apps">
          Distributed Apps are off-chain, blockchain-enabled applications that
          can connect and interact with smart contracts. They can offer users
          many novel business opportunities in developing P2P decentralized
          programs.
        </PointCards>
        <PointCards
          icon="fa-user-secret"
          title="Trustless Auditable Authorities"
        >
          Blockchain can act as a distributed, immutable data store that can
          both prove data integrity and act as an independent decentralized time
          server. Highly sensitive records can be tracked without leaking data
          while still providing auditable assurance.
        </PointCards>
        <PointCards icon="fa-search" title="Blockchain Analysis">
          Many blockchains have large amounts of data being added continuously:
          interactions with smart contracts, cryptocurrency transfers, and other
          such metadata. The open nature of many blockchain networks allows for
          a unique opportunity to pursue blockchains as sources for data mining
          and market analysis.
        </PointCards>
      </v-row>
      <p>
        When most people think of blockchain, they immediately think of
        cryptocurrency and often Bitcoin; for Bitcoin to be a reality however,
        many serious issues in distributed ledger technology needed to be
        solved. Blockchain was the solution. Those who have a computer science
        background may have read the Bitcoin whitepaper and found it interesting
        if relatively limited in use due to its time and space constraints and
        overall inefficiency compared to other technologies available. In the
        years after the bitcoin whitepaper was published, research has been
        ongoing, which brought about many advances and has opened up blockchain
        technology to many new markets.
      </p>
      <p>
        The number of market opportunities available now in building and leading
        the way in secure applications should not be overlooked by any business
        trying to compete in today's marketplace. Ethereum opened the door to
        smart contracts (programs which run on the blockchain) and the myriad of
        distributed apps that could interact with them. The Solidity programming
        language for building apps that could run on the Ethereum virtual
        machine has opened many new possibilities. Quorum, developed by Chase
        Bank, has added support for private Distributed Byzantine fault-tolerant
        consensus algorithms that have eliminated forks in private networks and
        introduced private data channels along with the public ones for
        intercompany networks. Hyperledger saw a use case in making more private
        and business-oriented networks. It introduced toolkits that support
        building custom blockchain apps running on private chains with their
        Chaincode programming language.
      </p>
    </PageSection>
    <PageSection color="#b8c4d4" class="grey--text text--darken-4 py-md-15">
      <h4 class="text-h4">Emphasis on Research-Backed Designs</h4>
      <v-row class="py-5">
        <PointCards light icon="fa-university" title="Recent Publications">
          New techniques, protocols, and consensus algorithms are being
          developed every day. We continually monitor academic and business
          publications to always be aware of new innovation and stay ahead of
          the competition.
        </PointCards>
        <PointCards light icon="fa-id-badge" title="Permissioned Networks">
          Many businesses need decentralized trust with known participants,
          which traditional blockchain networks do not assure. We offer
          research-backed network designs to ensure secure, permissioned control
          to protect business needs.
        </PointCards>
        <PointCards light icon="fa-mask" title="Anonomuous Actors">
          Sometimes a design requires user interaction to be anonymous. Whether
          your design requires complete transparency or total anonymity, we can
          guarantee these properties using the latest available research in
          private designs.
        </PointCards>
        <PointCards light icon="fa-lock" title="Modern Crypto">
          We are familiar with and keep up to date with modern cryptographic
          advancements in multiple fields, including blind signatures, ring
          signatures, and cryptographic zero-knowledge proofs. We do this to
          ensure your project will properly and securely meet its design goals.
        </PointCards>
      </v-row>
      <p>
        Rather than reinvent the wheel at our client's expense, our company
        follows all the latest developments in blockchain technology and
        relevant cryptographic concepts. This initiative allows us to build
        projects for our clients that are state of the art and follow the best
        practices in blockchain design. By following the latest research, we can
        work to fit client design goals best. Technologies already exist that
        can help promote either permissioned or anonymous networks and assure
        users have the authority to perform actions (even anonymously).
      </p>
      <p>
        Many fields of cryptography, which before were merely novel concepts,
        have found practical use in the blockchain world. Work on blind
        signatures, ring signatures, and zero-knowledge proofs are finding
        themselves used increasingly in blockchain designs to assure privacy,
        anonymity, eligibility, and ownership. Having knowledge and
        understanding of not only these concepts but also the continually
        improving research around them can help create designs and projects that
        otherwise would be impossible. Regardless of your project's needs,
        having a company with a solid understanding of the continually ongoing
        research in these fields can only benefit your project planning and
        design.
      </p>
    </PageSection>
    <PageSection>
      <div
        class="flex"
        style="position: relative; width: 100%; overflow: hidden;"
      >
        <particles
          id="particles-js-2"
          vue-class="align-self-stretch"
          style="position: absolute; width: 100%; z-index: auto;"
          :options="$options.particleJSON"
        ></particles>
        <v-container>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn x-large @click.stop="contactUs = true">
                <span class="text-h4 d-flex d-sm-none">
                  Contact us
                </span>
                <span class="text-h4 d-none d-sm-flex">
                  Contact Us Today!
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <p class="text-h6 text-center text--primary">
              If you are unsure if blockchain may be a good fit for your
              company, give us a call, and we will answer any questions you may
              have in our free consultation.
            </p>
          </v-row>
        </v-container>
      </div>
    </PageSection>
  </div>
</template>

<script>
import PageSection from '~/components/PageSection.vue'
import BlockStage from '~/components/BlockStage.vue'
import ContactUs from '~/components/ContactUs.vue'
import PointCards from '~/components/frontpage/PointCards.vue'
import particleConfig from '~/static/particles-config.json'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

export default {
  particleJSON: particleConfig,
  components: {
    PageSection,
    BlockStage,
    ContactUs,
    PointCards
  },
  data() {
    return {
      bannerHeight: 725,
      contactUs: false
    }
  },
  mounted() {
    console.log(this)
  },
  methods: {
    updateImageHeight() {
      this.$refs.banner.updateHeight()
    }
  }
}
</script>
