<template>
  <v-card
    :height="height"
    width="100%"
    max-width="500"
    :elevation="elevation"
    outlined
    shaped
  >
    <v-card-title :class="classN" style="word-break: normal;">
      <v-icon
        v-if="showIcon"
        aria-hidden
        x-large
        class="pr-4"
        color="primary"
        >{{ icon }}</v-icon
      >
      {{ title }}
    </v-card-title>
    <hr />
    <div class="pa-4">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Sample Title'
    },
    elevation: {
      type: Number,
      default: 5
    },
    classN: {
      type: String,
      default: 'justify-center text-center'
    },
    height: {
      type: String,
      default: '100%'
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    showIcon() {
      return this.icon !== ''
    }
  }
}
</script>
