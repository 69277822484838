<template>
  <v-dialog :value="contact" @input="$emit('input', false)">
    <v-row justify="center">
      <v-col cols="auto">
        <v-card>
          <!-- <h1 class="text-h2 text-center pb-2">Contact Us</h1>
          <hr /> -->
          <v-toolbar flat outlined>
            <v-spacer />
            <v-toolbar-title style="padding:30px;" class="text-h2">
              Contact Us
            </v-toolbar-title>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-intersect="{
                    handler: closeIntersect
                  }"
                  icon
                  v-bind="attrs"
                  @click="$emit('input', false)"
                  v-on="on"
                >
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" lg="5">
                <ContactCard />
              </v-col>
              <v-divider vertical />
              <v-col cols="12" lg="6">
                <ContactForm
                  :hide-close="hideFormClose"
                  @input="$emit('input', false)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import ContactForm from '~/components/ContactForm.vue'
import ContactCard from '~/components/ContactCard.vue'

export default {
  components: {
    ContactForm,
    ContactCard
  },
  props: {
    contact: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      hideFormClose: true
    }
  },
  computed: {},
  watch: {
    contact() {
      if (this.contact) {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden'
      } else {
        document.getElementsByTagName('html')[0].style.overflow = 'auto'
      }
    }
  },
  methods: {
    closeIntersect(entries, observer, isIntersecting) {
      this.hideFormClose = isIntersecting
    }
  }
}
</script>
