<template>
  <v-card shaped outlined class="pa-4">
    <v-alert
      v-if="serverError"
      color="error"
      dismissible
      outlined
      icon="mdi-alert"
      prominent
    >
      An error occured with submitting your form, this is on the server side and
      not an issue on your side. Please wait a few minutes and try again.
    </v-alert>
    <v-alert
      v-if="emailNotSent"
      color="warning"
      dismissible
      outlined
      icon="mdi-information-outline"
      prominent
    >
      {{ emailFailMessage }}
    </v-alert>
    <v-alert
      v-for="(alert, index) in returnedErrors"
      :key="index"
      v-model="alert.enabled"
      color="red"
      dismissible
      outlined
      prominent
      icon="mdi-alert"
      type="error"
    >
      Server Validation Error: {{ alert.message }}
    </v-alert>
    <v-form v-if="!formSubmitted" ref="formRef">
      <v-overlay absolute opacity="0.9" :value="formSubmitting">
        <v-progress-circular
          v-if="formSubmitting"
          size="100"
          :indeterminate="!showProgress"
          color="primary"
          :value="submissionProgress"
        />
      </v-overlay>
      <span class="text-h3 d-flex justify-center pb-2">
        Send an Inquiry
      </span>
      <v-row dense>
        <v-col>
          <v-select
            v-model="formData.inquirySelect"
            :items="inquiryItems"
            label="Inquiry Type"
            :rules="[requiredField]"
            validate-on-blur
            outlined
          ></v-select>
        </v-col>
        <v-col v-if="formData.inquirySelect === 'Other'" cols="10">
          <v-text-field
            v-model="formData.inquiryText"
            label="Type of Inquiry"
            :rules="[validateTypeField]"
            validate-on-blur
            outlined
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <v-combobox
            v-model="formData.honorific"
            outlined
            label="Honorific"
            :items="honorifics"
            :hint="honorHint"
            persistent-hint
            auto-select-first
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="formData.firstName"
            label="First Name"
            :rules="[requiredField]"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="formData.lastName"
            label="Last Name"
            :rules="[requiredField]"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="formData.email"
            type="email"
            :rules="[validateEmailField]"
            label="Email Address"
            validate-on-blur
            outlined
          />
        </v-col>
      </v-row>
      <v-row v-if="businessInquiry" dense>
        <v-col>
          <v-text-field
            v-model="formData.companyName"
            :rules="[requiredBusinessField]"
            label="Company Name"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.companyTitle"
            label="Company Title"
            :rules="[requiredBusinessField]"
            hint="What is your work title? (Developer, CTO, General Manager, etc)"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.phoneNumber"
            :rules="[validatePhone]"
            label="Phone Number"
            validate-on-blur
            outlined
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-textarea
            v-model="formData.inquiry"
            outlined
            auto-grow
            :label="inquiryLabel"
            :rules="[requiredField]"
            validate-on-blur
          />
        </v-col>
      </v-row>
      <v-row v-if="businessInquiry" dense>
        <v-col>
          <v-select
            v-model="formData.contactPreference"
            :items="contactPreference"
            label="Contact Preference"
            :rules="[requiredField]"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.zipCode"
            label="Zip Code"
            outlined
            :rules="[validateZipCode]"
            validate-on-blur
            hint="This helps us determine your timezone"
          />
        </v-col>
      </v-row>
      <v-row justify="space-around" dense>
        <v-col cols="auto">
          <v-btn
            color="primary"
            class="grey--text text--darken-4"
            @click="submitForm"
          >
            <v-icon class="pr-1">mdi-send</v-icon>
            Submit Inquiry
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="error"
            class="grey--text text--darken-4"
            @click="clearForm"
          >
            <v-icon class="pr-1">mdi-backspace</v-icon>
            Clear Form
          </v-btn>
        </v-col>
        <v-col v-if="showUndo" cols="auto">
          <v-btn
            color="warning"
            class="grey--text text--darken-4"
            @click="undoClear"
          >
            <v-icon class="pr-1">mdi-undo</v-icon>
            Undo Clear
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="formSubmitted">
      <v-tooltip v-if="!hideClose" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            class="float-right"
            @click="$emit('input', false)"
            v-on="on"
          >
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
      <v-row justify="center">
        <v-col cols="auto">
          <span class="text-h3 primary--text d-flex justify-center pb-2">
            Inquiry Sent
          </span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-icon class="d-flex" size="150" color="success">mdi-check</v-icon>
        </v-col>
      </v-row>
      <v-row justify="left">
        <v-col>
          <p>
            Thank you {{ name }} for your inquiry. It has been recieved; you can
            expect a {{ replyMethod }}.
          </p>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isPostalCode from 'validator/lib/isPostalCode'

export default {
  props: {
    hideClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inquiryItems: [
        'Consultation Request',
        'Business Inquiry',
        'Blockchain Question',
        'Comments/Suggestions',
        'Other'
      ],
      contactPreference: ['No Preference', 'E-mail', 'Phone', 'SMS'],
      honorifics: ['Mr.', 'Miss.', 'Mrs.', 'Ms.', 'Dr.', 'Professor'],
      formData: {
        inquirySelect: '',
        inquiryText: '',
        honorific: '',
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        companyTitle: '',
        phoneNumber: '',
        inquiry: '',
        contactPreference: '',
        zipCode: ''
      },
      clearedHistory: [],
      formSubmitting: false,
      formSubmitted: false,
      serverError: false,
      submitProgress: 0,
      respondProgress: 0,
      intervalTimer: {},
      returnedErrors: [],
      ipCount: 0,
      emailSent: false
    }
  },
  computed: {
    emailNotSent() {
      return !this.emailSent && this.formSubmitted
    },
    emailFailMessage() {
      if (this.ipCount <= 10) {
        return 'Due to a large volume of submissions, our server will not send you an acknowledgment email; rest assured, your inquiry was received.'
      } else {
        return `Due to a large number of submissions from your IP address, an acknowledgment email will not be sent out, and your inquiry may be delayed in response. Rest assured, your inquiry was received and will be responded to as soon as possible. We currently have recorded ${this.ipCount} submissions from this IP address in the past month.`
      }
    },
    submissionProgress() {
      return (this.submitProgress + this.respondProgress) * 100
    },
    businessInquiry() {
      if (
        this.formData.inquirySelect === 'Consultation Request' ||
        this.formData.inquirySelect === 'Business Inquiry' ||
        this.formData.inquirySelect === 'Other'
      ) {
        return true
      } else {
        return false
      }
    },
    businessRequired() {
      if (
        this.formData.inquirySelect === 'Consultation Request' ||
        this.formData.inquirySelect === 'Business Inquiry'
      ) {
        return true
      } else {
        return false
      }
    },
    showProgress() {
      if (this.submissionProgress > 0) {
        return true
      } else {
        return false
      }
    },
    inquiryLabel() {
      return {
        '': 'Inquiry',
        'Consultation Request': 'What is your business looking to do?',
        'Business Inquiry': 'Business Inquiry',
        'Blockchain Question': 'Blockchain Question',
        'Comments/Suggestions': 'Comments/Suggestions',
        Other: 'Inquiry'
      }[this.formData.inquirySelect]
    },
    honorHint() {
      return this.$vuetify.breakpoint.xs ? '' : 'You can enter a custom value'
    },
    showUndo() {
      if (this.clearedHistory.length === 0) {
        return false
      } else {
        return true
      }
    },
    name() {
      if (this.formData.honorific === null || this.formData.honorific === '') {
        return this.formData.firstName
      } else {
        return `${this.formData.honorific} ${this.formData.lastName}`
      }
    },
    replyMethod() {
      return {
        'No Preference': 'reply shortly',
        'E-mail': 'reply to your E-mail addrress shortly',
        Phone: 'call shortly',
        SMS: 'reply by text message shortly',
        '': 'reply to your E-mail address shortly'
      }[this.formData.contactPreference]
    }
  },
  methods: {
    clearForm() {
      this.clearedHistory.push({ ...this.formData })
      for (const key in this.formData) {
        this.formData[key] = ''
      }
      this.$refs.formRef.resetValidation()
    },
    undoClear() {
      this.formData = this.clearedHistory.pop()
    },
    validateTypeField(value) {
      if (this.formData.inquirySelect === 'Other') {
        if (value.length > 0) {
          return true
        } else {
          return 'Plese specify inquiry type'
        }
      } else {
        return true
      }
    },
    requiredField(value) {
      if (value.length > 0) {
        return true
      } else {
        return 'This is a required field'
      }
    },
    requiredBusinessField(value) {
      if (value.length > 0 || this.businessRequired === false) {
        return true
      } else {
        return 'This is a required field'
      }
    },
    validateEmailField(value) {
      if (isEmail(value)) {
        return true
      } else {
        return 'Please enter a valid email address'
      }
    },
    validateZipCode(value) {
      if (value === '' || isPostalCode(value, 'US')) {
        return true
      } else {
        return 'Please enter a valid US zipcode, OR leave blank'
      }
    },
    validatePhone(value) {
      if (
        !this.businessRequired ||
        isMobilePhone(value, ['en-US', 'en-CA', 'en-GB'])
      ) {
        return true
      } else {
        return 'Must be a valid US, CA, or GB, phone number. If you are from outside these localities please submit a request type other, and leave the field blank.'
      }
    },
    submitForm() {
      if (this.$refs.formRef.validate()) {
        this.formSubmitting = true
        this.formError = false
        const data = omitBy(this.formData, isEmpty)
        this.$axios
          .post('https://api.andersonsoftware.io/inquiry', data, {
            'content-type': 'application/json',
            onUploadProgress: (progressEvent) => {
              const progress = progressEvent.loaded / progressEvent.total
              this.submitProgress = progress / 2
            },
            onDownloadProgress: (progressEvent) => {
              const progress = progressEvent.loaded / progressEvent.total
              this.respondProgress = progress / 2
            }
          })
          .then((response) => {
            // Form submitted successfully
            this.formError = false
            this.returnedErrors = []
            this.emailSent = response.data.sent
            this.ipCount = response.data.total
            setTimeout(() => (this.formSubmitted = true), 750)
          })
          .catch((error) => {
            // Submission Failed for Some Reason
            if (error.response) {
              // Server responded with a status code not a 2xx error
              this.formSubmitting = false
              this.formSubmitted = false
              if (error.response.status >= 500) {
                this.serverError = true
              } else {
                console.log('Error Recieved attaching returnedErrors')
                this.returnedErrors = error.response.data.errors
                console.log(this.returnedErrors)
              }
              console.log(error.response)
            } else if (error.request) {
              // Request was made, no response Server Error
              this.formSubmitting = false
              this.formSubmitted = false
              this.serverError = true
              console.log(error.reeuest)
            } else {
              this.formSubmitting = false
              this.formSubmitted = false
              this.serverError = true
              console.log(error)
            }
          })
      } else {
        return false
      }
    }
  }
}
</script>
